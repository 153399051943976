import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
 


 <path d="M1395 2453 c-11 -2 -45 -8 -75 -15 -30 -6 -58 -11 -62 -10 -5 1 -8
-2 -8 -7 0 -5 -9 -11 -20 -14 -12 -3 -20 -14 -20 -25 0 -11 -5 -22 -11 -24
-19 -6 36 -63 97 -99 31 -19 70 -45 87 -59 17 -14 28 -19 23 -12 -4 7 12 -1
36 -18 23 -17 80 -57 124 -89 45 -31 95 -69 110 -83 16 -15 56 -50 88 -80 93
-84 206 -239 211 -288 1 -9 7 -25 12 -35 17 -33 23 -108 12 -150 -5 -22 -11
-45 -12 -51 -2 -6 -10 -22 -19 -35 -17 -24 -17 -24 -17 21 -1 50 -24 149 -43
183 -7 12 -12 25 -11 28 5 18 -187 245 -249 294 -7 6 -42 35 -79 65 -36 30
-76 63 -90 72 -71 50 -167 111 -185 118 -21 8 -143 105 -156 124 -4 5 -2 18 3
28 9 16 7 17 -16 6 -14 -6 -25 -18 -25 -26 0 -28 93 -106 212 -180 289 -180
486 -369 570 -547 41 -89 46 -195 11 -260 -53 -99 -177 -172 -307 -182 -74 -6
-75 -5 -115 29 -22 18 -59 43 -83 55 -24 12 -127 62 -231 112 -236 116 -264
139 -302 264 -20 62 -26 100 -22 129 8 56 23 69 48 39 14 -17 19 -39 19 -84
l0 -60 -22 19 c-20 17 -20 16 2 -12 32 -41 110 -92 301 -200 246 -138 315
-192 328 -256 l7 -33 32 32 c37 36 38 46 15 91 -42 76 -150 167 -343 287 -344
214 -386 255 -378 363 6 79 63 215 110 265 21 23 38 44 38 48 0 3 27 33 60 65
65 65 73 78 35 64 -36 -14 -104 -79 -125 -120 -10 -19 -32 -51 -48 -70 -17
-19 -41 -57 -54 -85 -13 -27 -26 -52 -31 -53 -4 -2 -5 -7 -3 -11 3 -4 -1 -32
-9 -61 -11 -42 -12 -63 -3 -94 6 -23 10 -57 9 -76 -10 -193 6 -258 79 -329 26
-25 55 -52 64 -59 21 -17 193 -102 205 -102 5 0 14 -7 21 -15 7 -8 20 -15 29
-15 23 0 208 -97 241 -125 24 -21 107 -38 148 -31 70 13 77 15 137 36 33 12
69 23 80 26 11 2 21 8 23 14 2 6 8 8 13 4 5 -3 9 0 9 5 0 6 5 11 11 11 26 0
149 99 149 120 0 6 6 10 13 10 25 0 124 192 142 275 17 85 9 202 -21 288 -64
185 -281 388 -552 518 -26 13 -49 25 -52 28 -15 17 -134 49 -165 44z m158 -77
c420 -194 653 -487 608 -766 -23 -142 -140 -319 -275 -417 -28 -21 -53 -40
-56 -43 -3 -3 -12 -7 -20 -9 -8 -2 -24 -6 -35 -8 -11 -3 7 11 40 31 297 182
294 457 -7 753 -108 106 -184 166 -343 270 -71 47 -155 102 -185 122 -30 20
-55 43 -55 51 0 20 122 64 195 69 8 1 68 -24 133 -53z m-583 -740 c7 -9 19
-16 26 -16 8 0 14 -4 14 -9 0 -5 14 -14 30 -21 17 -7 30 -15 30 -19 0 -3 23
-17 51 -31 27 -13 58 -32 67 -40 10 -9 40 -29 67 -45 107 -62 232 -166 266
-222 29 -47 15 -55 -23 -12 -32 35 -176 133 -288 194 -270 148 -292 165 -288
237 1 19 25 11 48 -16z"/>
<path d="M953 2092 c-21 -44 -21 -48 -6 -72 32 -51 116 -117 326 -259 254
-172 353 -264 418 -391 29 -56 31 -133 5 -173 -11 -16 -36 -37 -58 -48 -47
-24 -49 -32 -5 -17 111 39 176 122 161 208 -28 156 -223 355 -605 616 -109 75
-187 140 -194 165 -9 29 -18 23 -42 -29z m182 -136 c44 -31 88 -62 99 -69 52
-34 152 -110 158 -120 4 -7 8 -7 8 -2 0 6 17 -7 38 -28 20 -21 56 -53 79 -71
23 -19 39 -36 36 -40 -4 -3 -2 -6 4 -6 14 0 73 -57 108 -105 16 -22 32 -42 36
-45 28 -21 77 -182 49 -165 -7 4 -20 55 -19 71 0 4 -3 10 -8 13 -5 3 -20 25
-33 49 -13 23 -27 42 -32 42 -4 0 -8 5 -8 11 0 10 -81 93 -129 132 -55 45
-176 130 -271 192 -58 37 -118 78 -135 92 -122 98 -152 131 -141 160 6 14 12
11 44 -19 20 -20 73 -61 117 -92z"/>
<path d="M473 1013 c-46 -9 -63 -48 -63 -140 0 -76 2 -86 25 -108 32 -33 128
-38 174 -8 19 12 28 13 45 3 33 -17 59 -19 76 -5 20 16 59 25 65 15 7 -12 -6
-41 -15 -35 -5 3 -7 -1 -5 -7 1 -7 6 -28 10 -46 9 -39 42 -67 61 -51 9 7 19 7
32 0 16 -8 151 -13 327 -11 11 1 72 1 135 1 106 1 140 1 203 -2 86 -4 344 5
354 13 7 6 19 5 33 -2 16 -9 25 -8 36 1 10 8 18 8 27 1 26 -22 194 -17 220 6
20 18 22 64 5 92 -14 22 -12 26 23 35 18 4 31 1 42 -9 19 -20 36 -20 61 -1 16
12 23 12 45 0 40 -20 124 -22 154 -2 23 15 25 22 23 79 0 35 -5 69 -10 76 -5
8 -4 18 3 25 17 17 13 42 -8 61 -29 27 -126 33 -167 11 -29 -15 -35 -16 -54
-2 -26 18 -57 12 -91 -20 -22 -20 -29 -22 -35 -10 -5 7 -21 21 -35 31 -44 28
-101 -9 -105 -69 -1 -11 -2 -29 -3 -40 -1 -15 -8 -8 -26 24 -14 24 -23 46 -20
49 9 9 -48 42 -72 42 -26 0 -61 -44 -91 -115 -20 -50 -39 -69 -39 -40 0 8 3
15 7 15 4 0 7 18 8 40 2 31 -4 47 -27 72 -28 31 -33 33 -98 32 -37 0 -76 -5
-87 -11 -13 -7 -30 -6 -60 4 -61 21 -141 9 -163 -24 -13 -20 -14 -29 -5 -44
10 -15 10 -25 -1 -44 -13 -25 -13 -25 -50 40 -40 70 -70 87 -111 66 -28 -15
-96 -172 -96 -220 0 -30 -2 -33 -22 -27 -13 3 -29 6 -36 6 -7 0 -11 4 -8 9 3
5 6 31 7 57 1 42 7 55 38 87 34 36 35 39 21 64 -19 33 -67 47 -139 40 -89 -9
-117 -56 -62 -106 26 -23 30 -34 33 -88 2 -34 0 -64 -3 -67 -11 -12 -59 13
-53 27 8 21 -54 168 -84 200 -14 15 -36 27 -48 27 -28 0 -74 -46 -81 -82 -6
-32 -33 -38 -33 -8 0 11 -15 36 -32 55 -27 28 -42 35 -83 39 -27 2 -60 2 -72
-1z m129 -50 c14 -13 18 -31 18 -85 0 -93 -17 -110 -103 -106 l-62 3 -3 103
-3 102 67 0 c49 0 73 -5 86 -17z m195 5 c8 -11 53 -114 80 -185 4 -11 0 -14
-17 -11 -18 2 -29 19 -53 82 l-31 79 -17 -39 c-16 -38 -15 -39 5 -44 11 -3 22
-14 24 -23 3 -13 -4 -17 -31 -17 -26 0 -36 -5 -40 -20 -3 -12 -14 -20 -26 -20
-12 0 -21 1 -21 3 0 10 74 184 82 195 6 6 16 12 23 12 7 0 17 -6 22 -12z m313
-8 c0 -16 -7 -20 -35 -20 l-35 0 0 -85 c0 -78 -2 -85 -20 -85 -18 0 -20 7 -20
85 l0 85 -35 0 c-28 0 -35 4 -35 20 0 19 7 20 90 20 83 0 90 -1 90 -20z m212
-79 c21 -52 38 -98 38 -103 0 -4 -8 -8 -19 -8 -13 0 -24 14 -36 48 -10 26 -25
63 -33 82 l-15 35 -13 -35 c-17 -47 -17 -50 5 -50 19 0 35 -23 24 -34 -4 -3
-19 -6 -34 -6 -19 0 -30 -6 -34 -20 -6 -20 -45 -29 -45 -10 0 9 66 175 77 193
3 5 14 7 26 5 16 -2 29 -25 59 -97z m248 79 c0 -18 -7 -20 -80 -20 -73 0 -80
2 -80 20 0 18 7 20 80 20 73 0 80 -2 80 -20z m208 8 c20 -20 15 -86 -7 -100
-19 -11 -19 -13 5 -47 30 -44 30 -51 1 -51 -17 0 -28 11 -45 45 -17 36 -27 45
-47 45 -23 0 -25 -4 -25 -45 0 -38 -3 -45 -20 -45 -18 0 -20 7 -20 65 l0 65
65 0 c58 0 65 2 65 20 0 18 -7 20 -65 20 -58 0 -65 2 -65 20 0 18 7 20 73 20
43 0 78 -5 85 -12z m233 -83 c44 -104 46 -115 20 -115 -13 0 -24 14 -36 48
-10 26 -25 63 -33 82 l-15 35 -13 -35 c-17 -47 -17 -50 5 -50 19 0 35 -23 24
-34 -4 -3 -19 -6 -34 -6 -19 0 -30 -6 -34 -20 -6 -20 -45 -29 -45 -10 0 9 66
175 77 193 3 5 14 7 26 5 15 -2 30 -25 58 -93z m160 74 c6 -12 19 -44 28 -72
l17 -51 26 70 c23 63 28 69 57 72 l31 3 0 -105 c0 -102 -1 -106 -21 -106 -21
0 -22 3 -15 82 8 91 0 94 -30 10 -32 -92 -66 -89 -101 10 l-18 53 -3 -77 c-3
-70 -5 -78 -22 -78 -19 0 -20 7 -20 98 0 54 3 102 7 105 14 15 53 6 64 -14z
m369 1 c0 -18 -7 -20 -80 -20 -73 0 -80 2 -80 20 0 18 7 20 80 20 73 0 80 -2
80 -20z m-970 -80 c0 -18 -7 -20 -60 -20 l-60 0 0 -45 c0 -38 -3 -45 -20 -45
-17 0 -20 7 -20 53 0 29 5 58 11 64 6 6 42 11 80 12 63 1 69 -1 69 -19z m970
0 c0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 7 -20 60 -20 57 0 60 -1
60 -25 0 -24 -3 -25 -60 -25 -47 0 -64 4 -80 20 -23 23 -28 95 -7 103 6 3 42
6 80 6 61 1 67 -1 67 -19z m-956 -91 l5 -36 -47 2 c-62 3 -60 1 -54 30 3 15
13 27 26 30 29 6 61 11 63 11 0 -1 4 -17 7 -37z m-714 -69 c0 -5 -7 -10 -15
-10 -11 0 -15 -11 -15 -36 0 -21 -4 -33 -10 -29 -5 3 -10 19 -10 36 0 19 -5
29 -15 29 -8 0 -15 5 -15 10 0 6 18 10 40 10 22 0 40 -4 40 -10z m80 0 c0 -5
-13 -10 -30 -10 -16 0 -30 5 -30 10 0 6 14 10 30 10 17 0 30 -4 30 -10z m38
-22 c-2 -26 1 -33 14 -33 10 0 18 -4 17 -10 0 -8 -38 -9 -46 -1 -1 1 -5 19 -8
39 -5 30 -3 37 10 37 13 0 16 -8 13 -32z m122 22 c0 -5 -16 -10 -35 -10 -19 0
-35 5 -35 10 0 6 16 10 35 10 19 0 35 -4 35 -10z m80 0 c0 -5 -12 -10 -26 -10
-21 0 -25 -4 -22 -22 2 -17 9 -22 26 -20 15 2 22 -2 22 -13 0 -12 -5 -15 -16
-11 -9 3 -22 6 -30 6 -19 0 -29 47 -14 65 14 17 60 21 60 5z m88 -32 c3 -37 3
-38 -32 -38 -34 0 -36 2 -36 33 0 41 7 49 40 45 21 -3 25 -9 28 -40z m55 16
l14 -27 7 27 c11 42 36 33 36 -14 0 -41 -20 -58 -21 -17 0 21 -1 21 -10 -3
l-10 -25 -20 25 c-20 24 -21 24 -16 3 3 -13 1 -26 -4 -29 -5 -3 -9 15 -9 40 0
52 13 59 33 20z m110 0 l14 -27 7 27 c11 42 36 33 36 -14 0 -41 -20 -58 -21
-17 0 21 -1 21 -10 -3 l-10 -25 -20 25 c-20 24 -21 24 -16 3 3 -13 1 -26 -4
-29 -5 -3 -9 15 -9 40 0 52 13 59 33 20z m89 -6 c2 -21 8 -33 18 -33 10 0 16
12 18 33 2 17 8 32 13 32 6 0 9 -17 7 -37 -3 -34 -6 -38 -32 -41 -33 -4 -46
10 -46 51 0 38 18 34 22 -5z m112 5 l15 -28 1 28 c0 15 5 27 10 27 6 0 10 -18
10 -40 0 -48 -22 -54 -44 -12 l-15 27 -1 -33 c0 -20 -4 -31 -10 -27 -11 7 -14
68 -3 78 12 13 22 7 37 -20z m66 -19 c0 -27 -4 -43 -10 -39 -5 3 -10 24 -10
46 0 21 5 39 10 39 6 0 10 -21 10 -46z m90 36 c0 -5 -11 -10 -25 -10 -33 0
-35 -37 -2 -42 18 -2 29 -18 12 -18 -3 0 -16 0 -30 0 -22 0 -25 4 -25 33 0 19
3 37 7 40 10 11 63 8 63 -3z m76 -25 c18 -43 18 -66 1 -45 -13 17 -47 15 -47
-3 0 -7 -3 -8 -10 -1 -12 12 14 84 31 84 5 0 17 -16 25 -35z m104 25 c0 -5 -4
-10 -9 -10 -6 0 -11 -15 -13 -32 -2 -18 -7 -32 -13 -30 -5 1 -8 16 -7 32 3 22
-1 30 -12 30 -9 0 -16 5 -16 10 0 6 16 10 35 10 19 0 35 -4 35 -10z m40 -29
c0 -22 -4 -43 -10 -46 -6 -4 -10 12 -10 39 0 25 5 46 10 46 6 0 10 -18 10 -39z
m85 -1 c0 -32 -3 -35 -33 -38 -37 -4 -48 13 -38 54 6 21 11 25 39 22 29 -3 32
-6 32 -38z m59 13 l15 -28 1 28 c0 15 5 27 10 27 6 0 10 -18 10 -40 0 -48 -22
-54 -44 -12 l-15 27 -1 -33 c0 -20 -4 -31 -10 -27 -11 7 -14 68 -3 78 12 13
22 7 37 -20z m-1214 -13 c0 -5 -12 -11 -27 -11 l-28 -2 28 -4 c36 -6 35 -23
-3 -23 -25 0 -30 4 -30 25 0 21 5 25 30 25 17 0 30 -4 30 -10z m160 0 c0 -5
-12 -11 -27 -11 l-28 -2 28 -4 c36 -6 35 -23 -2 -23 -28 0 -47 22 -35 41 7 12
64 11 64 -1z"/>
<path d="M485 875 l0 -66 45 3 45 3 0 60 0 60 -45 3 -45 3 0 -66z"/>
<path d="M1230 685 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
<path d="M2052 688 c2 -13 10 -23 18 -23 8 0 16 10 18 23 3 17 -2 22 -18 22
-16 0 -21 -5 -18 -22z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
